import React, { useContext, useEffect, useState } from "react";
import "./HeaderTop.scss";
// img
import { ReactComponent as Facebook } from "../../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../../assets/instagram.svg";
import { ReactComponent as Youtube } from "../../../assets/youtube.svg";
import { ReactComponent as Tiktok } from "../../../assets/tiktok.svg";
import ApiService from "../../../api/axios";
import { localeContext } from "../../Store";
const HeaderTop = (props) => {
  const [locale, setLocale] = useContext(localeContext);
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    ApiService.get("helpers/languages").then((resp) => {
      setLanguages(resp?.data?.data);
    });
  }, [locale]);
  const changeLocale = (e, language) => {
    ApiService.get(`helpers/locale/${language}`).then((resp) => {
      localStorage.setItem("locale", resp?.data?.data);
      // you don't need to set locale here, because after reload, the locale will be set from localStorage
      // setLocale(language)
      window.location.reload();
    });
  };

  return (
    <div className="headerTop">
      <div className="container">
        <div className="left">
          <a target="_blank" href={props.socials.facebook}>
            <Facebook />
          </a>
          <a target="_blank" href={props.socials.instagram}>
            <Instagram />
          </a>
          <a target="_blank" href={props.socials.youtube}>
            <Youtube />
          </a>
          <a target="_blank" href={props.socials.tiktok}>
            <Tiktok />
          </a>
        </div>
        <div className="right">
          {languages.map((language) => {
            if (language.code != locale) {
              return (
                <button
                  key={language.id}
                  onClick={(e) => {
                    changeLocale(e, language.code);
                  }}
                >
                  {language.code}
                </button>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
