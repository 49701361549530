import React, { useState, useEffect } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import { _t } from "../../helpers/helpers";
import ApiService from "../../api/axios";

const Error = () => {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    ApiService.get("helpers/menu/header_menu").then((resp) => {
      setTags(resp?.data.menu_brands);
    });
  }, []);
  return (
    <div className="errorPage">
      <div className="container">
        <div className="error-page-content">
          <Link to="/">
            <Logo />
          </Link>
          <p>{_t("error.page.title")}</p>
        </div>
        <div className="errorContentBottom">
          <p>{_t("error.page.content")}</p>
          <div className="errorContentBottomLink">
            {tags?.map((tag) => {
              return <Link to={tag.alias}>{tag.title}</Link>;
            })}
          </div>
          <Link to="/" className="blackButton errorPageButton">
            {_t("cart.back_to_shop")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
