import React, { useContext, useEffect, useState } from "react";
import "./Products.scss";
import BreadCrumb from "../../components/BreadCrumb";
import { ReactComponent as Manat } from "../../assets/manatBlack.svg";
import { ReactComponent as Search } from "../../assets/search.svg";
import { ReactComponent as FilterAZ } from "../../assets/filter.svg";
import { ReactComponent as FilterZA } from "../../assets/filterza.svg";
import { ReactComponent as FilterStar } from "../../assets/Star.svg";
import { ReactComponent as Filter19 } from "../../assets/Filter19.svg";
import { ReactComponent as Filter91 } from "../../assets/filter91.svg";
import { ReactComponent as X } from "../../assets/x.svg";
import { ReactComponent as FilterButton } from "../../assets/filterButton.svg";
import { ReactComponent as XButton } from "../../assets/xButton.svg";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import Select from "react-select";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCard from "../../components/ProductCard";
import { _t } from "../../helpers/helpers";
import { cartItemsContext, quantityContext } from "../../components/Store";

const Products = (props) => {
  const [menuBrands, setMenuBrands] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [genders, setGenders] = useState([]);
  const [groups, setGroups] = useState([]);
  const [notes, setNotes] = useState([]);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageMeta, setPageMeta] = useState({});
  const [total, setTotal] = useState(0);
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState([]);
  const [currentBrand, setCurrentBrand] = useState("");

  const [sort, setSort] = useState({
    order_by: "title",
    direction: "ASC",
  });
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    genders: [],
    brands: [],
    notes: [],
    groups: [],
    authors: [],
    categories: [],
    stickers: [],
    keyword: "",
    max_price: 100000,
    min_price: 0,
    part_of_day:
      new URLSearchParams(props.location.search).get("part_of_day") && null,
    season: new URLSearchParams(props.location.search).get("season") && null,
  });
  const [cartItems, setCartItems] = useContext(cartItemsContext);
  const [quantity, setQuantity] = useContext(quantityContext);

  useEffect(() => {
    setProducts([]);
    let url = new URLSearchParams(props.location.search);
    let arrays = ["gender", "categorie", "sticker", "brand", "group", "note"];
    let newFilters = {
      ...filters,
      genders: [],
      categories: [],
      brands: [],
      notes: [],
      groups: [],
      authors: [],
      keyword: "",
      // min_price: null,
      // max_price: null,
      part_of_day:
        new URLSearchParams(props.location.search).get("part_of_day") && null,
      season: new URLSearchParams(props.location.search).get("season") && null,
    };
    setFilters(newFilters);
    setPage(1);
    // console.log(new URLSearchParams(props.location.search).get("sticker"));
    arrays.map((value) => {
      if (url.get(value)) {
        newFilters[`${value}s`] = [];
        newFilters[`${value}s`].push(url.get(value));
        setFilters(newFilters);
        if (value == "brand") {
          setCurrentBrand(url.get(value));
        }
      }
    });
    // console.log(currentBrand);
    init(newFilters, sort, page);

    ApiService.get("products/filters").then((resp) => {
      setAuthors(resp?.data.data.authors);
      setBrands(resp?.data.data.brands);
      setGenders(resp?.data.data.genders);
      setGroups(resp?.data.data.groups);
      setNotes(resp?.data.data.notes);
    });
    ApiService.get("helpers/menu/header_menu").then((resp) => {
      setMenuBrands(resp.data.menu_brands);
    });
  }, [props.location.search]);

  const options = [
    {
      value: { order_by: "title", direction: "ASC" },
      label: (
        <div className="filterInner">
          <FilterAZ />
          {_t("filter.from_a_to_z")}
        </div>
      ),
    },
    {
      value: { order_by: "title", direction: "DESC" },
      label: (
        <div className="filterInner">
          <FilterZA />
          {_t("filter.from_z_to_a")}
        </div>
      ),
    },
    {
      value: { order_by: "rating", direction: "DESC" },
      label: (
        <div className="filterInner filterInnerP">
          <FilterStar />
          {_t("filter.popularity")}
        </div>
      ),
    },
    {
      value: { order_by: "min_price", direction: "ASC" },
      label: (
        <div className="filterInner">
          <Filter19 />
          {_t("filter.cheap_to_expensive")}
        </div>
      ),
    },
    {
      value: { order_by: "min_price", direction: "DESC" },
      label: (
        <div className="filterInner">
          <Filter91 />
          {_t("filter.expensive_to_cheap")}
        </div>
      ),
    },
  ];
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      boxShadow: "0 .8rem 2rem rgba(196, 196, 196, 0.2)",
      borderRadius: "2rem",
      overflow: "hidden",
    }),

    option: (styles, state) => {
      return {
        ...styles,
        backgroundColor: "#fff",
      };
    },
  };
  // filter open close
  const [isFilter, setIsFilter] = useState(false);
  const openFilter = () => {
    setIsFilter(!isFilter);
    document.body.classList.add("body-overflow");
  };
  const closeFilter = () => {
    setIsFilter(false);
    document.body.classList.remove("body-overflow");
  };

  // filter category
  const [isFilterCategory, setIsFilterCategory] = useState(false);
  const filterAccordionCategory = () => {
    setIsFilterCategory(!isFilterCategory);
    setIsFilterPrice(false);
    setIsFilterGroup(false);
    setIsFilterBrand(false);
    setIsFilterNotes(false);
    setIsFilterPerfume(false);
    setIsFilterSeason(false);
    setIsFilterTime(false);
  };
  // filter category
  const [isFilterPrice, setIsFilterPrice] = useState(false);
  const filterAccordionPrice = () => {
    setIsFilterPrice(!isFilterPrice);
    setIsFilterCategory(false);
    setIsFilterBrand(false);
    setIsFilterGroup(false);
    setIsFilterNotes(false);
    setIsFilterPerfume(false);
    setIsFilterSeason(false);
    setIsFilterTime(false);
  };
  // filter brand
  const [isFilterBrand, setIsFilterBrand] = useState(false);
  const filterAccordionBrand = () => {
    setIsFilterBrand(!isFilterBrand);
    setIsFilterPrice(false);
    setIsFilterCategory(false);
    setIsFilterGroup(false);
    setIsFilterPerfume(false);
    setIsFilterNotes(false);
    setIsFilterSeason(false);
    setIsFilterTime(false);
  };
  // filter group
  const [isFilterGroup, setIsFilterGroup] = useState(false);
  const filterAccordionGroup = () => {
    setIsFilterGroup(!isFilterGroup);
    setIsFilterPrice(false);
    setIsFilterCategory(false);
    setIsFilterBrand(false);
    setIsFilterNotes(false);
    setIsFilterPerfume(false);
    setIsFilterSeason(false);
    setIsFilterTime(false);
  };
  // filter notes
  const [isFilterNotes, setIsFilterNotes] = useState(false);
  const filterAccordionNotes = () => {
    setIsFilterNotes(!isFilterNotes);
    setIsFilterPrice(false);
    setIsFilterCategory(false);
    setIsFilterBrand(false);
    setIsFilterGroup(false);
    setIsFilterPerfume(false);
    setIsFilterSeason(false);
    setIsFilterTime(false);
  };
  // filter perfume
  const [isFilterPerfume, setIsFilterPerfume] = useState(false);
  const filterAccordionPerfume = () => {
    setIsFilterPerfume(!isFilterPerfume);
    setIsFilterPrice(false);
    setIsFilterCategory(false);
    setIsFilterBrand(false);
    setIsFilterGroup(false);
    setIsFilterNotes(false);
    setIsFilterSeason(false);
    setIsFilterTime(false);
  };
  // filter season
  const [isFilterSeason, setIsFilterSeason] = useState(false);
  const filterAccordionSeason = () => {
    setIsFilterSeason(!isFilterSeason);
    setIsFilterPerfume(false);
    setIsFilterPrice(false);
    setIsFilterCategory(false);
    setIsFilterBrand(false);
    setIsFilterGroup(false);
    setIsFilterNotes(false);
    setIsFilterTime(false);
  };
  const [isFilterTime, setIsFilterTime] = useState(false);
  const filterAccordionTime = () => {
    setIsFilterTime(!isFilterTime);
    setIsFilterSeason(false);
    setIsFilterPerfume(false);
    setIsFilterPrice(false);
    setIsFilterCategory(false);
    setIsFilterBrand(false);
    setIsFilterGroup(false);
    setIsFilterNotes(false);
  };

  const init = (filters, sort, page, infinite = false) => {
    setLoading(true);
    setPage(page);
    if (infinite && page == 1) {
      setPage(2);
      page++;
    }
    let url = new URLSearchParams(props.location.search);
    let fields = {
      brands: filters.brands,
      authors: filters.authors,
      genders: filters.genders,
      notes: filters.notes,
      groups: filters.groups,
      season: filters.season,
      stickers: filters.stickers,
      categories: url.get("categories"),
      part_of_day: filters.part_of_day,
      order_by: sort.order_by,

      direction: sort.direction,
      page: page,
      keyword: url.get("keyword"),
      currentBrand: url.get("brand"),
      min_price: filters.min_price,
      max_price: filters.max_price,
    };
    console.log(filters.brands);
    ApiService.get("products/list", { params: fields }).then((resp) => {
      if (infinite) {
        setProducts([...products, ...resp.data.data]);
      } else {
        setProducts(resp?.data.data);
      }
      if (resp?.data.data.length === 0 || resp?.data.data.length > 50) {
        setLoading(false);
      }
      setPageMeta(resp?.data.page_meta);
      setPageBreadcrumbs(resp?.data.page_meta.breadcrumbs);
      setTotal(resp?.data.count);
    });
  };

  const [checked, setChecked] = useState(false);
  const handleFilter = (e, filterBy) => {
    if (filterBy === "category") {
      setChecked(!checked);
      let fil;

      if (e.target.checked) {
        fil = filters;
        fil[e.target.name].push(e.target.value);
        setFilters(fil);
      } else {
        fil = filters;
        let index = fil[e.target.name].indexOf(e.target.value);
        fil[e.target.name].splice(index, 1);
        setFilters(fil);
      }
      // clear all filters
      let newFilters = filters;
      newFilters.brands = [];
      // newFilters.categories = [];
      newFilters.notes = [];
      newFilters.genders = fil.genders;
      newFilters.groups = [];
      newFilters.authors = [];
      newFilters.stickers = [];
      newFilters.keyword = "";
      newFilters.max_price = 100000;
      newFilters.min_price = 0;
      newFilters.part_of_day =
        new URLSearchParams(props.location.search).get("part_of_day") && null;
      newFilters.season =
        new URLSearchParams(props.location.search).get("season") && null;
      setFilters(newFilters);
      const newSort = {
        order_by: "title",
        direction: "ASC",
      };
      setSort(newSort);
      setPage(1);

      init(newFilters, newSort, 1);
    } else {
      let newFilters = filters;
      setChecked(!checked);
      if (e.target.checked) {
        newFilters[e.target.name].push(e.target.value);
        setFilters(newFilters);
      } else {
        let index = newFilters[e.target.name].indexOf(e.target.value);
        if (index !== -1) {
          newFilters[e.target.name].splice(index, 1);
        }
      }

      init(newFilters, sort, page);
    }
  };
  const handleFilterSingle = (e) => {
    let fil = filters;
    setChecked(!checked);
    fil[e.target.name] = e.target.value;
    init(filters, sort, page);
  };
  const deleteFilterElement = (e, value, array) => {
    document.getElementById(value).style.display = "none";
    let fil = filters;
    let index = fil[array].indexOf(value);
    if (index !== -1) {
      fil[array].splice(index, 1);
    }
    init(filters, sort, page);
  };

  const sortProducts = (e) => {
    setSort(e.value);
    init(filters, e.value, page);
  };
  // notes
  const [searchNotes, setSearchNotes] = useState("");

  const onchangeNote = (e) => {
    setSearchNotes(e.target.value);
  };

  const filteredNote = !searchNotes
    ? notes
    : notes.filter((note) =>
        note.title.toLowerCase().includes(searchNotes.toLowerCase())
      );

  // groups
  const [searchGroup, setSearchGroup] = useState("");

  const onchangeGroup = (e) => {
    setSearchGroup(e.target.value);
  };

  const filteredGroup = !searchGroup
    ? groups
    : groups.filter((group) =>
        group.title.toLowerCase().includes(searchGroup.toLowerCase())
      );
  // brands
  const [searchBrand, setSearchBrands] = useState("");

  const onchangeBrand = (e) => {
    setSearchBrands(e.target.value);
  };

  const filteredBrand = !searchBrand
    ? brands
    : brands.filter((brand) =>
        brand.title.toLowerCase().includes(searchBrand.toLowerCase())
      );

  // authors
  const [searchAuthor, setSearchAuthor] = useState("");

  const onchangeAuthor = (e) => {
    setSearchAuthor(e.target.value);
  };

  const filteredAuthor = !searchAuthor
    ? authors
    : authors.filter((author) =>
        author.title.toLowerCase().includes(searchAuthor.toLowerCase())
      );

  const handlePrice = (e) => {
    let fil = filters;
    fil.min_price = e.target.value.split(",")[0];
    fil.max_price = e.target.value.split(",")[1];

    setFilters(fil);
    // console.log(filters);
    init(filters, sort, 1);
  };

  return (
    <div className="products">
      <div className="container">
        <div className="row">
          <div className="xl-12 lg-12 md-12 sm-12">
            <BreadCrumb breadcrumbs={pageBreadcrumbs} />
            <div className="row productsHeader">
              <div className="xl-3 lg-3 md-3 sm-12 productsHeaderTitle">
                {pageMeta?.meta_title}
                <span>({total + " " + _t("search.count")})</span>
              </div>
              <div className="xl-9 lg-9 md-9 sm-12">
                <div className="productsTopFilter">
                  <div className="productsTopFilterChild">
                    {filters?.genders.map((gender) => {
                      return (
                        <span id={gender}>
                          {_t(gender)}
                          <X
                            onClick={(e) =>
                              deleteFilterElement(e, gender, "genders")
                            }
                          />
                        </span>
                      );
                    })}
                    {filters?.brands.map((brand) => {
                      return (
                        <span id={brand}>
                          {brand}
                          <X
                            onClick={(e) =>
                              deleteFilterElement(e, brand, "brands")
                            }
                          />
                        </span>
                      );
                    })}
                    {filters?.groups.map((group) => {
                      return (
                        <span id={group}>
                          {group}
                          <X
                            onClick={(e) =>
                              deleteFilterElement(e, group, "groups")
                            }
                          />
                        </span>
                      );
                    })}
                    {filters?.notes.map((note) => {
                      return (
                        <span id={note}>
                          {note}
                          <X
                            onClick={(e) =>
                              deleteFilterElement(e, note, "notes")
                            }
                          />
                        </span>
                      );
                    })}
                    {filters?.authors.map((author) => {
                      return (
                        <span id={author}>
                          {author}
                          <X
                            onClick={(e) =>
                              deleteFilterElement(e, author, "authors")
                            }
                          />
                        </span>
                      );
                    })}
                  </div>
                  <Select
                    classNamePrefix="filter"
                    options={options}
                    onChange={(e) => sortProducts(e)}
                    components={{ IndicatorSeparator: () => null }}
                    defaultValue={options[0]}
                    styles={customStyles}
                  />
                  <button
                    className="filterButton desk-none"
                    onClick={openFilter}
                  >
                    <FilterButton />
                    {_t("buttons.filter")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="xl-3 lg-3 md-3 sm-12">
            <div
              className={isFilter ? "productsFilter active" : "productsFilter "}
            >
              <div className="filterMobileHeader">
                <button className="close" onClick={closeFilter}>
                  <XButton />
                </button>
                <div className="filterName">
                  <FilterButton />
                  {_t("buttons.filter")}
                </div>
                <button className="clear">{_t("buttons.clear")}</button>
              </div>
              <form className="productFilterSection">
                <div className="productFilterSectionChild">
                  <h5
                    className={isFilterBrand ? "title active" : "title"}
                    onClick={filterAccordionBrand}
                  >
                    {_t("title.brands")}
                    <Arrow />
                  </h5>
                  <div
                    className={
                      isFilterBrand
                        ? "filterContentParent active"
                        : "filterContentParent"
                    }
                  >
                    <div className="filterSearch">
                      <input
                        type="text"
                        placeholder={_t("input_brandSearch_placeholder")}
                        onChange={onchangeBrand}
                      />
                      <button>
                        <Search />
                      </button>
                    </div>
                    <div className="filter-content active">
                      {filteredBrand?.map((brand) => {
                        return (
                          <label
                            htmlFor=""
                            className="filterCheckbox"
                            key={brand.id}
                          >
                            <span>
                              {brand.title} ({brand.count}){" "}
                            </span>
                            <input
                              type="checkbox"
                              name="brands"
                              value={brand.alias}
                              onChange={(e) => handleFilter(e)}
                              checked={filters.brands.includes(brand.alias)}
                              id={brand.id}
                            />
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="productFilterSectionChild">
                  <h5
                    className={isFilterCategory ? "title active" : "title"}
                    onClick={filterAccordionCategory}
                  >
                    {_t("title.categories")}
                    <Arrow />
                  </h5>
                  <div
                    className={
                      isFilterCategory
                        ? "filter-content active"
                        : "filter-content"
                    }
                  >
                    {/* <Genders /> */}
                    {genders?.map((gender) => {
                      return (
                        <label
                          htmlFor=""
                          className="filterCheckbox"
                          key={gender.id}
                        >
                          <span>
                            {gender.title} ({gender.count}){" "}
                          </span>
                          <input
                            type="checkbox"
                            name="genders"
                            value={gender.id}
                            checked={
                              filters.genders.includes(gender.id) ? true : false
                            }
                            onChange={(e) => handleFilter(e, "category")}
                            id={gender.id}
                          />
                          <span className="checkmark"></span>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div className="productFilterSectionChild">
                  <h5
                    className={isFilterPrice ? "title active" : "title"}
                    onClick={filterAccordionPrice}
                  >
                    {_t("title.price")}
                    <Arrow />
                  </h5>
                  <div
                    className={
                      isFilterPrice
                        ? "filterContentParent active"
                        : "filterContentParent"
                    }
                  >
                    {/* <div className="filterInputsection">
                      <div className="filterInputsSectionChild">
                        <input
                          type="number"
                          placeholder={_t("input.least.placeholder")}
                        />
                        <span>–</span>
                        <input
                          type="number"
                          placeholder={_t("input.most.placeholder")}
                        />
                      </div>
                      <button className="resp-none">
                        <Search />
                      </button>
                    </div> */}
                    <div className="filter-content active">
                      {/* <label htmlFor="" className="filterRadio">
                        <span>
                          0 – 50 <Manat />
                        </span>
                        <input type="radio" name="radio" id="" />
                        <span className="checkmark"></span>
                      </label>
                      <label htmlFor="" className="filterRadio">
                        <span>
                          50 – 100 <Manat />
                        </span>
                        <input type="radio" name="radio" id="" />
                        <span className="checkmark"></span>
                      </label>
                      <label htmlFor="" className="filterRadio">
                        <span>
                          100 – 200 <Manat />{" "}
                        </span>
                        <input type="radio" name="radio" id="" />
                        <span className="checkmark"></span>
                      </label>
                      <label htmlFor="" className="filterRadio">
                        <span>
                          200 <Manat /> – {_t("more_than")}{" "}
                        </span>
                        <input type="radio" name="radio" id="" />
                        <span className="checkmark"></span>
                      </label> */}
                      <label
                        htmlFor=""
                        className="filterRadio"
                        onClick={closeFilter}
                      >
                        <span>
                          0 – 50 <Manat />
                        </span>
                        <input
                          type="radio"
                          name="radio"
                          id=""
                          value="0,50"
                          onChange={(e) => handlePrice(e)}
                          checked={
                            !!(
                              filters.min_price.toString().includes("0") &&
                              filters.max_price.toString().includes("50")
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label
                        htmlFor=""
                        className="filterRadio"
                        onClick={closeFilter}
                      >
                        <span>
                          50 – 100 <Manat />
                        </span>
                        <input
                          type="radio"
                          name="radio"
                          id=""
                          value="50,100"
                          onChange={(e) => handlePrice(e)}
                          checked={
                            !!(
                              filters.min_price.toString().includes("50") &&
                              filters.max_price.toString().includes("100")
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label
                        htmlFor=""
                        className="filterRadio"
                        onClick={closeFilter}
                      >
                        <span>
                          100 – 200 <Manat />
                        </span>
                        <input
                          type="radio"
                          name="radio"
                          id=""
                          value="100,200"
                          onChange={(e) => handlePrice(e)}
                          checked={
                            !!(
                              filters.min_price.toString().includes("100") &&
                              filters.max_price.toString().includes("200")
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label
                        htmlFor=""
                        className="filterRadio"
                        onClick={closeFilter}
                      >
                        <span>
                          200 <Manat /> – {_t("input.much.more")}
                        </span>
                        <input
                          type="radio"
                          name="radio"
                          id=""
                          value="200,1000000"
                          onChange={(e) => handlePrice(e)}
                          checked={
                            !!(
                              filters.min_price.toString().includes("200") &&
                              filters.max_price.toString().includes("1000000")
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label
                        htmlFor=""
                        className="filterRadio"
                        onClick={closeFilter}
                      >
                        <span>{_t("buttons.allParfume")}</span>

                        <input
                          type="radio"
                          name="radio"
                          id=""
                          value="0,10000000"
                          onChange={(e) => handlePrice(e)}
                          checked={
                            !!(
                              filters.min_price.toString().includes("0") &&
                              filters.max_price.toString().includes("1000000")
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="productFilterSectionChild">
                  <h5
                    className={isFilterGroup ? "title active" : "title"}
                    onClick={filterAccordionGroup}
                  >
                    {_t("title.groups")}
                    <Arrow />
                  </h5>
                  <div
                    className={
                      isFilterGroup
                        ? "filterContentParent active"
                        : "filterContentParent"
                    }
                  >
                    <div className="filterSearch">
                      <input
                        type="text"
                        placeholder={_t("input_groupSearch_placeholder")}
                        onChange={onchangeGroup}
                      />
                      <button>
                        <Search />
                      </button>
                    </div>
                    <div className="filter-content active">
                      {/* <Groups /> */}
                      {filteredGroup?.map((group) => {
                        return (
                          <label
                            htmlFor=""
                            className="filterCheckbox"
                            key={group.id}
                          >
                            <span>
                              {group.title} ({group.count}){" "}
                            </span>
                            <input
                              type="checkbox"
                              name="groups"
                              value={group.alias}
                              onChange={(e) => handleFilter(e)}
                              checked={filters.groups.includes(group.alias)}
                              id={group.id}
                            />
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="productFilterSectionChild">
                  <h5
                    className={isFilterNotes ? "title active" : "title"}
                    onClick={filterAccordionNotes}
                  >
                    {_t("title.notes")}
                    <Arrow />
                  </h5>
                  <div
                    className={
                      isFilterNotes
                        ? "filterContentParent active"
                        : "filterContentParent"
                    }
                  >
                    <div className="filterSearch">
                      <input
                        type="text"
                        placeholder="Not axtar.."
                        onChange={onchangeNote}
                      />
                      <button>
                        <Search />
                      </button>
                    </div>
                    <div className="filter-content active">
                      {filteredNote?.map((note) => {
                        return (
                          <label
                            htmlFor=""
                            className="filterCheckbox"
                            key={note.id}
                          >
                            <span>
                              {note.title} ({note.count})
                            </span>
                            <input
                              type="checkbox"
                              name="notes"
                              value={note.alias}
                              onChange={(e) => handleFilter(e)}
                              checked={filters.notes.includes(note.alias)}
                              id={note.id}
                            />
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="productFilterSectionChild">
                  <h5
                    className={isFilterPerfume ? "title active" : "title"}
                    onClick={filterAccordionPerfume}
                  >
                    {_t("title.parfumer")}

                    <Arrow />
                  </h5>
                  <div
                    className={
                      isFilterPerfume
                        ? "filterContentParent active"
                        : "filterContentParent"
                    }
                  >
                    <div className="filterSearch">
                      <input
                        type="text"
                        placeholder={_t("input.parfumer_search.placehholder")}
                        onChange={onchangeAuthor}
                      />
                      <button>
                        <Search />
                      </button>
                    </div>
                    <div className="filter-content active">
                      {filteredAuthor?.map((author) => {
                        return (
                          <label
                            htmlFor=""
                            className="filterCheckbox"
                            key={author.id}
                          >
                            <span>
                              {author.title} ({author.count}){" "}
                            </span>
                            <input
                              type="checkbox"
                              name="authors"
                              value={author.alias}
                              onChange={(e) => handleFilter(e)}
                              checked={filters.authors.includes(author.alias)}
                              id={author.id}
                            />
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="productFilterSectionChild">
                  <h5
                    className={isFilterSeason ? "title active" : "title"}
                    onClick={filterAccordionSeason}
                  >
                    {_t("title.choose_parfume_season")}
                    <Arrow />
                  </h5>
                  <div
                    className={
                      isFilterSeason
                        ? "filter-content active"
                        : "filter-content"
                    }
                  >
                    <label htmlFor="" className="filterRadio">
                      <span>{_t("winter_autumn_perfume")} </span>
                      <input
                        type="radio"
                        name="season"
                        onChange={(e) => handleFilterSingle(e)}
                        checked={filters.season === "winter-fall"}
                        value="winter-fall"
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label htmlFor="" className="filterRadio">
                      <span>{_t("summer_spring_perfume")}</span>
                      <input
                        type="radio"
                        name="season"
                        onChange={(e) => handleFilterSingle(e)}
                        checked={filters.season === "spring-summer"}
                        value="spring-summer"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="productFilterSectionChild">
                  <h5
                    className={isFilterTime ? "title active" : "title"}
                    onClick={filterAccordionTime}
                  >
                    {_t("title.choose_perfume_time")}
                    <Arrow />
                  </h5>

                  <div
                    className={
                      isFilterTime ? "filter-content active" : "filter-content"
                    }
                  >
                    <label htmlFor="" className="filterRadio">
                      <span>{_t("title.daytimeParfume")}</span>
                      <input
                        type="radio"
                        value="day"
                        onChange={(e) => handleFilterSingle(e)}
                        checked={filters.part_of_day === "day"}
                        name="part_of_day"
                        id="day"
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label htmlFor="" className="filterRadio">
                      <span>{_t("title.night_parfume")}</span>
                      <input
                        type="radio"
                        name="part_of_day"
                        value="night"
                        onChange={(e) => handleFilterSingle(e)}
                        checked={filters.part_of_day === "night"}
                        id="night"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <button className="showProduct">
                  {_t("button.show_product")}
                </button>
              </form>
            </div>
          </div>
          <div className="xl-9 lg-9 md-9 sm-12">
            <InfiniteScroll
              dataLength={products?.length}
              next={() => init(filters, sort, page + 1, true)}
              loader={_t("site_content.loading")}
              hasMore={loading}
              className={"row flex flex-wrap"}
            >
              {products?.map((product) => (
                <div key={product.id} className="xl-4 lg-4 md-4 sm-6">
                  <ProductCard product={product} />
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <Meta meta={pageMeta} />
      <div className="productPageAbout">
        <div className="container">
          <div className="productPageAboutContent">
            <div
              dangerouslySetInnerHTML={{
                __html: pageMeta?.content,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
