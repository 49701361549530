import React, {useContext, useEffect, useState} from 'react';
import './About.scss';
import ApiService from '../../../api/axios';
import {localeContext} from "../../Store";
const About = (props) => {
    const [about, setAbout] = useState({});
    const [locale,setLocale] =useContext(localeContext);
    useEffect(() => {
        ApiService.get("index/about").then(
            (resp) => {
                setAbout(resp.data.data)
            }
        )

    },[locale])
    return (
        <div className="aboutSection">
            <div className="container">
                <div className="aboutSectionChild">
                    <div className="title">
                        {about.title}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: about.content,
                        }}>
                    </div>
        
                </div>
            </div>
        </div>
    )
}

export default About
