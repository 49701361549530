import React, { useEffect, useState } from "react";
import "./Collection.scss";
import BreadCrumb from "../../components/BreadCrumb";
import CollectionImg from "../../assets/col.png";
import { Link } from "react-router-dom";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import { _t } from "../../helpers/helpers";

const Collection = () => {
  const [collections, setCollections] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    ApiService.get("/specials").then((resp) => {
      setCollections(resp.data.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
      console.log(resp);
    });
  }, []);

  return (
    <div className="collection">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="collectionHeader">
          <h4 className="title">{pageMeta.title}</h4>
          <p>{pageMeta.description}</p>
        </div>
      </div>
      <div className="container-second">
        {collections &&
          collections.map((collection) => {
            return (
              <div className="row" key={collection.id}>
                <div className="xl-6 lg-6 md-6 sm-12 collectionCard">
                  <Link to={collection.alias} className="collectionImgCard">
                    <div className="collectionImgCardChild">
                      <img src={collection.title_image} alt="collection" />
                    </div>
                  </Link>
                </div>
                <div className="xl-6 lg-6 md-6 sm-12 collectionCard">
                  <div className="collectionTextParent">
                    <div className="collectionText">
                      <h6 className="title">{collection.title}</h6>
                      <p
                      // dangerouslySetInnerHTML={{
                      //   __html: collection.content,
                      // }}
                      >
                        {collection.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="container">
        <div className="collectionBottom">
          <div
            dangerouslySetInnerHTML={{
              __html: pageMeta.content,
            }}
          ></div>
          <Link to="/" className="blackButton">
            Özəl ətİr üçün müracİət et
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Collection;
