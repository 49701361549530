import React, { useEffect, useState } from "react";
import "./News.scss";
import NewsCard from "../../NewsCard";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";

SwiperCore.use([Navigation]);

const News = () => {
  const [newsList, setNewsList] = useState([]);
  useEffect(() => {
    ApiService.get("index/featured_news").then((resp) => {
      setNewsList(resp.data.data);
    });
  }, []);

  return (
    <div className="newsSection">
      <div className="container">
        <div className="title">{_t("title.news")}</div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={16}
          slidesPerView={4}
          loop={false}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            1200: {
              slidesPerView: 3,
            },
            767: {
              slidesPerView: 2.5,
            },
            500: {
              slidesPerView: 1.5,
            },
            320: {
              slidesPerView: 1,
            },
          }}
          className="paginationSwiper pagination_desk_none"
        >
          {newsList
            .slice(0)
            .reverse()
            .map((news) => (
              <SwiperSlide key={news.id}>
                <NewsCard news={news} />
              </SwiperSlide>
            ))}
        </Swiper>

        <div className="more-category  resp-none">
          <Link to="/blog">{_t("title.all_news")}</Link>
        </div>
      </div>
    </div>
  );
};

export default News;
