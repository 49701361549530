import React, { useState } from "react";
import "./ForgetPassword.scss";
import InputMask from "react-input-mask";
import { _t } from "../../../helpers/helpers";
import ApiService from "../../../api/axios";

const ForgetPassword = (props) => {
  const [phone, setPhone] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone !== 0) {
      ApiService.post("/auth/getResetOTP", { phone }).then((resp) => {
        if (resp.data.success) {
          props.history.push("/reset-password");
        }
      });
    }
  };

  return (
    <div className="forgetPassword">
      <div className="container">
        <div className="forgetPasswordContent">
          <div className="title">{_t("buttons_forgot_password")}</div>
          <form action="" onSubmit={handleSubmit}>
            <div className="input-group input-group-validate">
              <InputMask
                mask="+\9\9\4999999999"
                maskChar=" "
                name="phone"
                placeholder={_t("table.header_phone")}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <button className="blackButton">{_t("buttons.send")}</button>
            <span className="forgetInfo">
              {_t("conf.code.sent.email")}
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
