import React, { useState } from "react";
import Swal from "sweetalert2";
import ApiService from "../../../api/axios";
import { _t } from "../../../helpers/helpers";
import "../Authentication.scss";

const ResetPassword = (props) => {
  const [new_password, setPassword] = useState("");
  const [new_password_confirmation, setPassword_confirmation] = useState("");
  const [otp, setOtp] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    ApiService.post("auth/reset", {
      password: new_password,
      password_confirmation: new_password_confirmation,
      code: otp,
    }).then((resp) => {
      localStorage.setItem("token", resp.data.token);
      localStorage.setItem("wishlist", resp.data.user.wishlist);
      localStorage.setItem("full_name", resp.data.user.fullname);
      Swal.fire(resp.data.title, resp.data.message, "success");
      props.history.push("/");
      window.location.reload();
    });
  };

  return (
    <div className="forgetPassword">
      <div className="container">
        <div className="forgetPasswordContent">
          <div className="title">{_t("buttons_forgot_password")}</div>
          <form action="" onSubmit={handleSubmit}>
            <div className="input-group phone">
              <input
                type="number"
                placeholder={_t("buttons_otp_code")}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div className="input-group input-group-validate">
              <input
                type="password"
                placeholder={_t("forms.new_password")}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="input-group input-group-validate">
              <label htmlFor=""></label>
              <input
                type="password"
                placeholder={_t("forms.repeat_password")}
                name="password_confirmation"
                onChange={(e) => setPassword_confirmation(e.target.value)}
              />
            </div>

            <button className="blackButton">{_t("buttons.send")}</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
